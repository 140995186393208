import React, { useEffect } from 'react';
import styled from '@emotion/styled';

import { Page, Content } from '../theme/page';
import Typography from '../atoms/typography';
import Button from '../atoms/button';
import TaxonShop from '../tissues/category-taxon-shop';

const PageContent = styled(Content)`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  margin-bottom: ${(props) => props.theme.modularScale.base};
  width: 120px;
`;

const Title = styled(Typography)`
  color: ${(props) => props.theme.color.brand};
  margin-bottom: ${(props) => props.theme.modularScale.xlarge};
`;

const Body = styled(Typography)`
  color: ${(props) => props.theme.color.brand};
  margin-bottom: ${(props) => props.theme.modularScale.large};
  max-width: 380px;
  text-align: center;
  width: 100%;
`;

const Cta = styled(Button)`
  margin-bottom: ${(props) => props.theme.modularScale['4xlarge']};
`;

const taxonData = {
  one_line: true,
  heading: 'Shop by Category',
  as_taxon: false,
  elements: [
    {
      path: '/shop/baby',
      icon: {
        url: '/images/404/maisonette-baby.png',
        width: 130,
        height: 130,
        alt: 'Shop baby!',
        mime: 'image/png'
      },
      heading: 'Baby'
    },
    {
      path: '/shop/kids',
      icon: {
        url: '/images/404/maisonette-kids.png',
        width: 130,
        height: 130,
        alt: 'Shop kids',
        mime: 'image/png'
      },
      heading: 'Kids'
    },
    {
      path: '/shop/play',
      icon: {
        url: '/images/404/maisonette-play.png',
        width: 130,
        height: 130,
        alt: 'Shop play',
        mime: 'image/png'
      },
      heading: 'Play'
    },
    {
      path: '/shop/home',
      icon: {
        url: '/images/404/maisonette-home.png',
        width: 130,
        height: 130,
        alt: 'Shop home',
        mime: 'image/png'
      },
      heading: 'Home'
    },
    {
      path: '/shop/gifts',
      icon: {
        url: '/images/404/maisonette-gifts.png',
        width: 130,
        height: 130,
        alt: 'Shop gifts',
        mime: 'image/png'
      },
      heading: 'Gifts'
    }
  ]
};

const FourOhFour = () => {
  useEffect(() => {
    global.window.scrollTo(0, 0);
  }, []);

  return (
    <Page background="cream" id="maincontent">
      <PageContent layout="large">
        <figure>
          <Image src="/images/error.png" alt="Error" />
        </figure>
        <Title element="h3" like="heading-4">Page Not Found!</Title>
        <Body element="p" like="paragraph-2">
          Seems you’ve fallen down the rabbit hole. Make like Alice and find your way back by
          choosing a category below. No eating little cakes required.
        </Body>
        <Cta outline isLink href="/">Go to homepage</Cta>
      </PageContent>
      <Content>
        <TaxonShop data={taxonData} uppercaseTitles paddingsOnMobile />
      </Content>
    </Page>
  );
};

export default FourOhFour;
